<template>
    <div id="qsn">
        <div animated fadeDown class="header">
            <h2>
                Nous nous engageons à fournir des soins attentifs et personnalisés
                visant la réhabilitation totale de nos patients
            </h2>
        </div>
        <div animated fadeUp class="qsn">
            <div class="content">
                <h1>Qui sommes nous?</h1>
                <p>
                    FAL.Care Santé Mentale est une organisation d'aide et de soins à domicile spécialisée en psychiatrie. <br>
                    Reconnue par les assurances maladie, elle vise la réhabilitation des patients souffrant de troubles psychiques par l'adoption d'un modèle de psychiatrie intégrée et communautaire. Elle met en œuvre les politiques publiques de promotion et de prévention de la santé mentale.
                </p>
                <p>
                    Chez FAL.Care Santé Mentale, le personnel soignant dispense, sur prescription médicale, les prestations de 
                    soins reconnues au sens de l'Ordonnance sur les Prestations de l'Assurance pour les Soins (OPAS)
                </p>
            </div>
            <img src="@/assets/background/region.jpg" alt="">
        </div>
        <img animated fade src="@/assets/emote/emoticon_b.svg" alt="" class="emo">

    </div>
    <div class="collaborateur">
        <h1><span class="ssub">Fondateur</span> et directeur de l'organisation</h1>
        <div animated fade class="principal">
            <img src="@/assets/collaborator/fondateur_2.jpeg" alt="">
            <div class="prin_content">
                <h2>Khaled <br> Falfoul</h2>
                <p class="subtitle">Clinicien spécialisé en santé mentale</p>
                <p>
                    Titulaire d’un Master en Sciences ainsi qu’au bénéfice de plus de 10 ans d’expérience 
                    en psychiatrie hospitalière et ambulatoire. Au bénéfice d'une formation approfondie 
                    dans la gestion des troubles émotionnels et comportementaux, différentes techniques 
                    telles que le biofeedback et la thérapie par la réalité virtuelle (TRV) sont proposés.
                </p>
            </div>
        </div>
        <h1><span class="ssub">Équipe de soins</span></h1>
        <div animated scaleUp class="colaborateur_">
            <CardCollaborator v-for="collaborateur in collaborateurs" :name="collaborateur.name" :surname="collaborateur.surname" :desc="collaborateur.desc" :key="collaborateur.name"/>
        </div>
    </div>
    <div class="questions_container">
        <h1>Questions fréquentes</h1>
        <div animated fade class="questions">
            <questionsLine v-for="question in questions" :question="question.question" :response="question.response" :key="question.question"/>
        </div>
    </div>
</template>

<script>
    import CardCollaborator from './cardCollaborator.vue'
    import questionsLine from './questionsLine.vue';

    export default {
        name: 'QsnAccueil',
        data() {
            return {
                collaborateurs: [
                    {
                        name: "Alexandra",
                        surname: "Miceli",
                        desc: `Infirmière en psychiatrie depuis 20 ans, diplômée d’état des hôpitaux de Paris, j’ai obtenu mon CAS en sexologie en 2023.
                                J’ai travaillé pendant 10 ans avec des enfants et adolescents en soins aigus à Paris et Neuchâtel. J’ai également une expérience de 10 ans avec des patients adultes en service d’admissions crises, aux urgences psychiatriques et en service de transition au CNP.
                                Mon rôle est de soutenir les patients adolescents ou adultes dans leurs difficultés au quotidien et dans leur problématique sexuelle. Je suis également responsable des soins de l’institution.  Le travail en équipe et en collaboration, avec la famille et le réseau professionnel, est pour moi indispensable pour une prise en charge globale et donner du sens au suivi.
                                `
                    },
                    {
                        name: "Aline",
                        surname: "Dreyfuss",
                        desc: `
                                Je suis infirmière HES, diplômée en 2009. J’ai obtenu un CAS en psychiatrie et santé sociale en 2010 et une formation en psycho-traumatologie centrée compétences en 2022.
                                J’ai travaillé au centre de psychiatrie du nord-vaudois, aux urgences psychiatriques, dans un service ambulatoire de crise puis à l’équipe mobile de transition.
                                J’ai ensuite quitté le nord vaudois pour travailler au centre neuchâtelois de psychiatrie, en ambulatoire puis à l’équipe de transition.
                                Infirmière clinicienne, responsable du site de Neuchâtel.
                        `
                    },
                    {
                        name: "Celine",
                        surname: "Sahin",
                        desc: `Infirmière HES diplômée depuis 2018. 
                                En 2021, j’ai suivi une formation sur le processus de Rétablissement en santé mentale, et en 2022 une formation en psycho-traumatologie contrée compétences
                                J’ai commencé à pratiquer dans le canton de Vaud en psychiatrie communautaire en EMS et EPSM, puis dans le canton de Neuchâtel en hospitalier et aux urgences auprès du CNP
                                    Infirmière, site de La Chaux-de-Fond
                                `
                    },
                    {
                        name: "Emmy",
                        surname: "Totel",
                        desc: `Infirmière en psychiatrie depuis 2018, j’ai obtenu mon diplôme d’état en France.
                                J’ai travaillé pendant 2ans dans le milieu hospitalier de la psychiatrie auprès des jeunes adultes, adultes et personnes âgées dans la région parisienne. 
                                J’ai ensuite décidé de venir travailler au CNP auprès des personnes adultes en hospitalier à Préfargier, puis aux urgences psychiatriques pendant 3ans.
                                Mon rôle est d’accompagner et de soutenir les patients adultes face à leurs difficultés et besoins. En plus, des suivis individuels, je suis également responsable des activités thérapeutiques
                                `
                    },
                    {
                        name: "Mirielle",
                        surname: "pellaud",
                        desc: `
                                Mirielle Pellaud est une hypnothérapeute et assistante en soins et santé communautaire experte dans 
                                l'activation de l'inconscient pour faciliter les changements nécessaires au bien-être de ses clients. 
                                Son approche basée sur l'hypnothérapie explore les ressources insoupçonnées présentes dans l'inconscient 
                                de chacun. En tant que praticienne en hypnose, elle guide ses clients à travers les portes de leur propre 
                                inconscient pour initier les transformations souhaitées. Pendant les séances, bien que le conscient puisse 
                                être moins actif, c'est l'inconscient qui prend le contrôle et choisit les suggestions auxquelles il réagit. 
                                La maîtrise de l'hypnothérapeute réside dans sa capacité à adapter avec précision ses techniques à 
                                l'inconscient de chaque individu, le soutenant ainsi dans son cheminement vers des changements positifs. 
                                Au-delà de la partie visible de l'iceberg qu'est le conscient, l'hypnothérapeute guide ses clients à explorer 
                                et à exploiter les richesses et la complexité de leur être pour favoriser l'épanouissement personnel.
                            `
                    },
                    {
                        name: "Maika",
                        surname: "Razzano",
                        desc: `
                            L’assitante en soins et santé communautaire, Maika Razzano, joue un rôle primordial dans la prise en charge 
                            et l'accompagnement des personnes de tous âges, que ce soit dans leur quotidien ou lors de périodes de maladie. 
                            Sous la supervision du personnel infirmier, Maika assure le bien-être physique, social et psychologique des individus 
                            en organisant des activités adaptées à leurs besoins et en étant attentif à leurs habitudes. En plus d'offrir un soutien moral, 
                            Maika peut également être amené à réaliser certains soins médicaux et techniques. Son sens de l'écoute, sa capacité d'adaptation 
                            et sa bienveillance sont des atouts essentiels pour mener à bien sa mission auprès des personnes en situation de fragilité.
                            `
                    }

                ],
                questions: [
                    {
                        question: "Qui peut bénéficier des prestations fournit par FAL.Care Santé Mentale ?",
                        response: "Toute personne ayant besoin d’aide ou de soins psychiatriques, que ce soit pour cause de maladies psychiatriques, de troubles émotionnels, de troubles phobiques ou de burn-out."
                    },
                    {
                        question: "Quel est l’objectif de la prise en charge chez FAL.Care Santé Mentale ?",
                        response: "Grâce aux prestations d’aide et de soins à domicile, les personnes concernées peuvent continuer à vivre dans leur environnement familier en dépit de leurs difficultés. Il est également possible de les réintégrer plus rapidement à leur domicile à la suite d'une hospitalisation. Les prestations ont pour objectif principal de maintenir et de stimuler l’autonomie à domicile."
                    },
                    {
                        question: "Pourquoi choisir FAL.Care Santé Mentale comme prestataire de soin ?",
                        response: "Les prestations dispensées sont adaptées aux besoins réels des patients et prennent en compte leur entourage et leur environnement social. De plus, la prise en charge à domicile coûte moins cher à la collectivité qu'un placement en institution."
                    }
                ]
            }
        },
        methods: {
            //
        },
        components: {
            CardCollaborator,
            questionsLine
        }
    }
</script>

<style scoped>

div.header{
    background: #7CBD8A;
    text-align: center;
    color: var(--white);
    padding-top: 5em;
    padding-bottom: 10em;
}

div.header h2{
    font-size: 1.2em;
    width: 45%;
    margin: auto;
}

div.qsn{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    column-gap: 3vh;
    background-color: #49584C;
    padding: 5em;
    width: 90%;
    margin: auto;
    border-radius: 10px;
    transform: translateY(-5em);
    position: relative;
}

/* children of qsm 50% */
div.qsn div.content{
    width: 50%;
    text-align: left;
    height: 100%;
    display: flex;
    justify-content: center;
}

div.qsn div.content h1{
   text-align: left;
   width: 100%;
}

div.qsn div.content p{
    text-align: left;
    font-family: "Archia", sans-serif;
}

div.qsn img{
    width: 50%;
    border-radius: 10px;
}

img.emo{
    width: 10%;
    position: absolute;
    left: 5%;
    bottom: 5em;
    transform: translate(-30%, 30%);
    z-index: -1;
}

div#qsn {
  color: var(--white);
  width: 100%;
  position: relative;
}

div#qsn > h1 {
    text-align: center;
    margin-bottom: 7vh;
    position: relative;
}

div#qsn > div.wrapper > div{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    column-gap: 3vh;
    align-items: stretch;
    margin-bottom: 5vh;
    min-height: 184px;
}

div#qsn > div.wrapper div:nth-child(odd){
    padding-left: 8vh;
}

div#qsn > div.wrapper div:nth-child(even){
    padding-right: 8vh;
    flex-direction: row-reverse;
}

div#qsn > div.wrapper  div  .text-part{
    width: calc(80% - 4vh - 3vh/2);
}

div#qsn > div.wrapper  div  img{
    width: calc(20% - 4vh - 3vh/2);
}

/* think line under text */
span.ssub{
    position: relative;
    padding-left: 4px;
    font-weight: larger;
}
span.ssub::after {
    content: "";
    width: 100%;
    display: inline-block;
    position: absolute;
    height: 30%;
    background: #A8D3B1;
    left : 0;
    bottom: 0;
    z-index: -1;
}

div.collaborateur{
    width: 60%;
}

div.collaborateur div.principal{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    column-gap: 3vh;
    align-items: stretch;
    margin-bottom: 5vh;
    min-height: 184px;
    margin-top: 3em;
}

div.collaborateur div.principal img{
    width: 30%;
    border-radius: 10px;
    filter: grayscale(100%);
    font-weight: 900;
}

div.collaborateur div.principal div.prin_content{
    width: 70%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

div.collaborateur div.principal div.prin_content h2{
    font-size: 3em;
    color: #7CBD8A;
    text-transform: uppercase;
}

div.collaborateur div.principal div.prin_content p{

    font-family: 'Archia';
    font-style: normal;
    font-weight: 400;
    font-size: 1.2em;
    line-height: 29px;
    color: #1E1E2F;
}

div.collaborateur div.principal div.prin_content p.subtitle{
    font-family: 'Sequel Sans';
    font-style: normal;
    font-weight: 420;
    font-size: 1em;
    line-height: 24px;
    color: #000000;
}

div.colaborateur_{
    display: flex;
    flex-wrap: wrap;
    gap: 1em;
    padding: 0.5em;
    margin-top: 4em;
}

div.questions_container{
    width: 100%;
    background: #49584C;
    padding: 5em 0;
    color: var(--white);
    transition: height 0.5s ease-in-out;
    margin-top: 5em;
}

div.questions{
    width: 60%;
    margin: auto;
}

div.questions_container h1{
    width: 60%;
    text-align: left;
    position: relative;
    margin: auto;
}

@media only screen and (max-device-width: 600px ){
    div#qsn > div.wrapper div:nth-child(odd){
        padding-left: 0vh;
    }
    
    div#qsn > div.wrapper div:nth-child(even){
        padding-right: 0vh;
    }
    div#qsn > div.wrapper  div  .text-part{
        width: 100%;
    }

    div#qsn > div.wrapper > div{
        display:block;
    }

    div#qsn > div.wrapper  div  img{
        float: right;
        width: 30%;
    }

    div#qsn > div.wrapper  div  .text-part{
        font-size: small;
    }

    div#qsn > div.wrapper  div  .text-part h2{
        font-size: 1.5em;
    }
    div.collaborateur {
        width: 100%;
    }

    div.header h2{
        font-size: 1em;
        width: 90%;
    }

    div.qsn{
        display: flex;
        flex-direction: column;
        padding: 0;
    }

    div.qsn div.content{
        width: 100%;
        text-align: center;
        height: 100%;
        display: flex;
        justify-content: center;
        padding: 0.5em;
    }

    div.qsn div.content h1{
        text-align: center;
        width: 100%;
        font-size: 1.8em;
    }

    div.qsn img{
        width: 100%;
        border-radius: 10px;
        padding: 0.5em;
    }

    div.collaborateur div.principal{
        flex-direction: column;
    }

    img.emo{
        width: 20%;
        left: 8%;
    }
    div.collaborateur div.principal div.prin_content{
        width: 100%;
        padding: 0.5em;
        justify-content: center;
        align-items: center;
    }

    div.collaborateur div.principal div.prin_content p {
        padding: 0.5em;
        text-align: center;
    }

    div.collaborateur h1{
        font-size: 2em;
        padding: 0.5em;
    }

    div.collaborateur div.principal img{
        width: 70%;
        margin: auto;

    }

    div.questions{
        width: 100%;
        padding: 0.5em; 
    }

    div.questions_container h1{
        width: 100%;
        text-align: center;
        padding: 0.5em;
    }
}
</style>