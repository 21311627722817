<template>
    <div id="prestation" class="main">
        <div class="wrapper">
            <h1><span class="ssub">Prestations de soins</span></h1>
            <div  class="carte right border_left first">
                <div animated fadeRight class="content">
                    <div class="title">
                        <img class="emo" src="@/assets/emote/emoticon_a.svg" alt="">
                        <h2>Soins infirmiers</h2>
                    </div>
                    <ul >
                        <li class="justified-text">
                            L’évaluation, le conseil et l’accompagnement centré 
                            sur les besoins réels du patient et la coordination 
                            de soins entre les différents intervenants dans la 
                            prise en charge
                        </li>
                        <li class="justified-text">Examens et traitements</li>
                        <li class="justified-text">L’éducation thérapeutique à l’autogestion de la maladie et des traitements</li>
                        <li class="justified-text">La gestion des émotions par le biais des exercices psychocorporels</li>
                        <li class="justified-text">
                            La gestion des troubles phobiques et de traumatismes psychologiques
                            (la thérapie par la réalité virtuelle TRV)
                        </li>
                    </ul>
                </div>
                <img animated fadeRight src="@/assets/background/si.jpg" alt="">
            </div>
            <div class="carte small border_top border_right border_bottom">
                <img animated fade src="@/assets/background/sb.jpg" alt="">
                <div animated fade class="content forceleft">
                    <div class="title">
                        <img class="emo" src="@/assets/emote/emoticon_b.svg" alt="">
                        <h2>Les soins de base</h2>
                    </div>
                    <p>
                        Les mesures d’aide à l’orientation, à la structuration et à la 
                        sécurité, pour les personnes atteintes de troubles psychiques. 
                        Ces prestations visent l’amélioration de l’autonomie des patients.
                    </p>
                </div>
                <div animated fade class="content">
                    <div class="title">
                        <img class="emo" src="@/assets/emote/emoticon_b.svg" alt="">
                        <h2>Aide au ménage</h2>
                    </div>
                    <p>
                        FAL.Care Santé Mentale propose des prestations d’aide au ménage afin d'accomplir des tâches
                        ménagères y compris des acrtivités de grand nettoyage et veille a la propreté et l'orde du cadre de vie.
                    </p>
                </div>
            </div>
            <div class="carte small border_left border_bottom">
                <div animated fade class="content">
                    <div class="title">
                        <img class="emo" src="@/assets/emote/emoticon_b.svg" alt="">
                        <h2>thérapie psychocorporelle</h2>
                    </div>
                    <p>
                        La thérapie psychocorporelle appartient au champ de la psychothérapie humaniste et place 
                        l’individu au cœur même du processus thérapeutique. Elle se base sur cette synergie existante 
                        entre le corps et le psychisme : le corps enregistrerait notre vécu personnel et héritage familial, 
                        il garderait les traces de toutes nos blessures émotionnelles et souvenirs douloureux. Les techniques utilisées sont
                        TRV : Thérapie Réalité Virtuelle, le Biofeedback, La pleine conscience et la Relaxation.
                    </p>
                </div>
                <img animated fade src="@/assets/background/Therapie-psychocorporelle.jpg" alt="">
            </div>
            <div class="carte left border_right">
                <img animated fadeLeft src="@/assets/background/hypnose.png" alt="">
                <div animated fadeLeft class="content">
                    <div class="title">
                        <img class="emo" src="@/assets/emote/emoticon_b.svg" alt="">
                        <h2>Hypnose thérapeutique</h2>
                    </div>
                    <p>
                        L'hypnose thérapeutique est une approche complémentaire qui exploite l'état 
                        modifié de conscience pour accéder à l'inconscient et favoriser le changement. 
                    </p>
                    <p>
                        Guidé par un hypnothérapeute, le patient est conduit dans un état de relaxation 
                        profonde où il peut explorer les racines de ses problèmes et mobiliser ses propres 
                        ressources internes pour trouver des solutions.
                    </p>
                    <p>
                        Les séances utilisent des techniques telles que la relaxation guidée, la suggestion 
                        hypnotique et la visualisation pour traiter une gamme de troubles émotionnels et comportementaux.
                    </p>
                </div>
            </div>
            <div class="carte small border_left border_top">
                <div animated fade class="content">
                    <div class="title">
                        <img class="emo" src="@/assets/emote/emoticon_a.svg" alt="">
                        <h2>Thérapie de couple</h2>
                    </div>
                    <p>
                        La thérapie de couple en psychiatrie est un domaine spécialisé de la santé mentale qui vise à 
                        aider les couples à surmonter des problèmes relationnels, à améliorer leur communication et à 
                        renforcer leur intimité. Chez FAL.Care Santé Mentale, nos thérapeutes spécialisés travaillent 
                        avec les couples pour les aider à mieux se comprendre, à résoudre les conflits et à retrouver 
                        une relation équilibrée et épanouissante. Nous proposons des séances de thérapie personnalisées 
                        en fonction des besoins de chaque couple pour les accompagner vers un meilleur bien-être relationnel.
                    </p>
                </div>
                <img animated fade src="@/assets/background/therapie_de_couple.png" alt="">
            </div>
            <div class="carte small border_right border_top border_bottom">
                <img animated fade src="@/assets/background/sexologue.jpg" alt="">
                <div animated fade class="content">
                    <div class="title">
                        <img class="emo" src="@/assets/emote/emoticon_a.svg" alt="">
                        <h2>La sexothérapie</h2>
                    </div>
                    <p>
                        La sexothérapie est une forme de thérapie qui se concentre sur l'exploration 
                        et le traitement des problèmes liés à la sexualité, aux relations intimes, et à 
                        l'intimité. Les sexothérapeutes sont des professionnels formés pour aider les 
                        individus et les couples à surmonter des difficultés telles que les troubles de 
                        l'érection, la libido faible, les traumatismes sexuels, ou les problèmes de communication 
                        dans le couple. Ce type de thérapie implique généralement le développement d'emplément 
                        de thérapie saine et la mise en place de stratégies de gestion. Si vous avez d'autres 
                        questions ou si vous souhaitez plus d'informations sur la sexothérapie, n'hésitez pas à demander.
                    </p>
                </div>
            </div>
            <div class="carte right border_left">
                <div animated fadeRight class="content">
                    <div class="title">
                        <img class="emo" src="@/assets/emote/emoticon_a.svg" alt="">
                        <h2>Cours proche aidant</h2>
                    </div>
                    <p>
                        Chez FAL.Care Santé Mentale, nous proposons des cours et des programmes spécialement conçus pour les proches 
                        aidants des personnes atteintes de maladies psychiatriques. Ces formations visent à fournir aux proches 
                        aidants les outils, les informations et le soutien nécessaires pour mieux comprendre la maladie mentale, 
                        apprendre à gérer les situations difficiles et à prendre soin d'eux-mêmes tout en soutenant leur proche. 
                        Nos cours abordent des thèmes tels que la communication efficace, la gestion du stress, la compréhension 
                        des troubles mentaux et la mise en place de stratégies d'accompagnement adaptées. Nous offrons un environnement 
                        bienveillant et professionnel pour accompagner les proches aidants dans leur rôle crucial et les soutenir dans 
                        leur parcours.
                    </p>
                </div>
                <img animated fadeRight src="@/assets/background/proche_aidant.jpg" alt="">
            </div>
            <div class="carte blanc border_top border_right">
            </div>
            <Newsletter/>
        </div>
    </div>
</template>

<script>
    import Newsletter from './NewsLetter.vue'

    export default {
        name: 'PrestationAccueil',
        data() {
            return {
                //
            }
        },
        methods: {
            //
        },
        components: {
            Newsletter
        }
    }
</script>

<style scoped>

.justified-text{
    text-align: justify;
}

/* li bullet point */
ul {
    list-style-type: circle;
    padding: 0;
    margin: 0;
}

.wrapper{
    position: relative;
}

div.main h1{
    text-align: center;
    margin-top: 2em;
    margin-bottom: 2em;
    font-size: 2em;
    font-weight: bold;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
}

span.ssub{
    position: relative;
    padding-left: 4px;
    font-weight: larger;
}
span.ssub::after {
    content: "";
    width: 100%;
    display: inline-block;
    position: absolute;
    height: 30%;
    background: #A8D3B1;
    left : 0;
    bottom: 0;
    z-index: -1;
}

div.carte {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    gap: 1em;
    position: relative;
}


div.title{
    display: flex;
    align-items: center;
    gap: 1em;
    margin-bottom: 1em;
}

div.title img{
    width: 4em;
    height: 4em;
    margin-bottom: 0.5rem;
}

div.content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-left: 2em;
}
div.carte img + div.content{
    padding-left: 0;
    padding-right: 2em;
}

/* if first child is img then text align right otherwise text align left in .content */
div.content p, div.content ul{
    text-align: left;  

    font-family: 'Archia';
    font-style: normal;
    font-weight: 400;
    font-size: 1.2em;
    line-height: 29px;
    color: #1E1E2F;
}

div.content h2{
    text-align: left;
    font-family: 'Sequel Sans';
    font-style: normal;
    font-weight: 420;
    font-size: 4em;
    line-height: 76px;

    color: #49584C;
}

div.carte img + div.content p, div.carte img + div.content ul, div.carte img + div.content h2{
    text-align: right;
}

.first{
    padding-top: 10em !important;	
}

.border_left{
    border-left: 10px solid #49584C;
    padding-left: 4em !important;
}

.border_top{
    border-top: 10px solid #49584C;
}

.border_right{
    border-right: 10px solid #49584C;
    padding-right: 4em !important;
}

.border_bottom{
    border-bottom: 10px solid #49584C;
}


/* variable */

div.main{
    --width-right: 90%;
    --width-small: 80%;
    --width-left: calc( 100% + var(--width-small) - var(--width-right) );
    --margin--left-small: calc( 100% - var(--width-right) );
    --widht-blanc: calc(50% + var(--width-small) - var(--width-right));
}

.blanc{
    width: var(--widht-blanc);
    margin-left: calc( 50% - var(--widht-blanc));
    height: 10em;
}

div.content.forceleft p{
    text-align: left !important;
}

.right{
    float: right;
    width: var(--width-right);
    padding-top: 5em;
    padding-bottom: 5em;
}

.left{
    float: left;
    width: var(--width-left);
    padding-top: 5em;
    padding-bottom: 5em;
}

.small{
    width: var(--width-small);
    padding: 5em 0;
    margin-left: var(--margin--left-small);
}

img {
    width: 50%;
    height: 100%;
    border-radius: 10px;
}

@media only screen and (max-device-width: 600px ){
    div.wrapper {
        position: relative;
        width: 100%;
    
    }
    div.carte{
        flex-direction: column;
        gap: 1em;
        text-align: center;
        justify-content: center;
        width: 100%;
        border: none;
        margin: 0;
        padding: 0 !important;
    }

    div.main{
        width: 100%;
    }

    div.title {
        width: 100%;
        padding: 0;
    }
    div.content h2{
        font-size: 3em;
    }

    div.main h1{
        position: relative;
    }

    /* column reverse if carte first child is an image */
    div.carte > img {
        order : 2;
    }
    div.carte > div.content:first-child {
        order : 1;
    }

    div.title{
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 1em;
        margin-bottom: 1em;
    }
    div.title img{
        width: 2em;
        height: 2em;
    }

    div.title h2{
        font-size: 1.8em;
    }
    div.content {
        width: 100%;
        padding : 0.5em !important;
        text-align: center !important;
        height: fit-content;
        min-height: auto;
        justify-content: flex-end;
    }
    div.content p, div.content ul{
        text-align: center !important;
        font-size: 1em;
    }

    div.carte > img {
        width: 80%;
    }

    div.carte.right > img {
        /* make it start right (flex) */
        margin-left: auto;
        border-radius: 10px 0 0 10px;
    }

    div.carte.left > img {
        /* make it start right (flex) */
        margin-right: auto;

        /* border radius left = 0 */
        border-radius: 0 10px 10px 0;
    }
}
</style>